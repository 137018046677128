import styled from "@emotion/styled";
import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import ModalConfirmVote from "./ModalConfirmVote";
import { useEffect } from "react";

const ModalViewProject = (props) => {
    const {
        openModal,
        handleCloseModal,
        postulation,
        colorPrimary,
        handleSendVote,
        id,
        loadingVote,
        resetErrorVote,
        errorVote,
        upDateVote,
        file_link,
        videoLink,
        projectFile,
    } = props;

    // STATES
    const [openModalVote, setOpenModalVote] = useState(false);
    const [numberVotes, setNumberVotes] = useState(postulation?.votes);

    //EFFECTS
    useEffect(() => {
        !!upDateVote.update &&
            id === upDateVote.id &&
            setNumberVotes(numberVotes + 1);

        // eslint-disable-next-line
    }, [upDateVote]);

    // FUNCTIONS
    const handleCloseModalVote = () => {
        setOpenModalVote(false);
    };

    return (
        <>
            <ModalConfirmVote
                openModalVote={openModalVote}
                handleCloseModalVote={handleCloseModalVote}
                postulation={postulation}
                colorPrimary={colorPrimary}
                handleSendVote={handleSendVote}
                id={id}
                loadingVote={loadingVote}
                errorVote={errorVote}
            />
            <ModalFake
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <BoxFake>
                    <WrapperVideo>
                        {!!projectFile ? (
                            <Video autoplay muted controls>
                                <source
                                    src="video.hevc"
                                    type="video/mp4; codecs=hevc"
                                />
                                <source src={projectFile} type="video/mp4" />
                            </Video>
                        ) : (
                            <Iframe
                                id="iframe"
                                src={file_link || videoLink}
                                frameborder="0"
                                allowfullscreen="true"
                                webkitallowfullscreen="true"
                                mozallowfullscreen
                            ></Iframe>
                        )}
                    </WrapperVideo>
                    <Body>
                        <Header colorPrimary={`#${colorPrimary}`}>
                            <h2>{postulation?.project_name}</h2>
                            <span>{postulation?.participants_names}</span>
                        </Header>
                        <Content>
                            <p>{postulation?.project_description}</p>
                        </Content>
                        <Footer>
                            <Votes>
                                <p>
                                    <span>{numberVotes}</span> votos
                                </p>
                            </Votes>
                            <WrapperButtons>
                                <Button
                                    colorPrimary={`#${colorPrimary}`}
                                    colorText={"#fff"}
                                    onClick={() => {
                                        resetErrorVote();
                                        setOpenModalVote(true);
                                    }}
                                >
                                    Votar
                                </Button>
                            </WrapperButtons>
                        </Footer>
                    </Body>
                </BoxFake>
            </ModalFake>
        </>
    );
};

export default ModalViewProject;

const ModalFake = styled(Modal)``;

const BoxFake = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    background-color: #fff;
    box-shadow: 24px;
    display: flex;
    justify-content: space-between;
    align-items: Center;
    border-radius: 20px;
    background-color: #222222;
    overflow: hidden;

    @media (max-width: 820px) {
        flex-direction: column;
    }
`;

const WrapperVideo = styled.div`
    flex-basis: 65%;
    width: 100%;
    height: max-content;
    position: relative;
`;

const Body = styled.div`
    flex-basis: 35%;
    width: calc(100% - 3rem);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background-color: #fff;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-start;

    h2 {
        color: #000;
        font-size: 1.3rem;
    }

    span {
        color: ${(p) => p.colorPrimary};
        font-size: 0.85rem;
    }
`;

const Content = styled.div`
    width: 100%;
    height: 193px;
    padding-right: 0.2rem;
    overflow: auto;

    p {
        font-size: 0.9rem;
        color: #616161;
        line-height: 140%;
        letter-spacing: 0.0025em;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem 0;
`;

const Votes = styled.div`
    width: 100%;

    p {
        text-align: start;
        font-size: 1.2rem;

        span {
            font-weight: bold;
            font-size: 1.35rem;
        }
    }
`;

const WrapperButtons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
`;

const Button = styled.button`
    width: 100%;
    color: ${(p) => p.colorText};
    background-color: ${(p) => p.colorPrimary};
    border: none;
    padding: 1rem;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
`;

const Iframe = styled.iframe`
    border: none;
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
`;

const Video = styled.video`
    border: none;
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`;
