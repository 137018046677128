import axios from "axios";

export const getPostulations = async (id) => {
    try {
        const req = await axios.get(
            `${process.env.REACT_APP_CMS_ADEN}/api/contest-postulations?populate=*&filters[contest_simulator][id][$eq]=${id}`
        );
        return req.data.data;
    } catch (error) {
        console.error("getPostulations", error);
        throw new Error(error);
    }
};

export const handlePutVote = async (idPostulation) => {
    try {
        const req = await axios.put(
            `${process.env.REACT_APP_CMS_ADEN}/api/contest-postulations/vote/${idPostulation}`
        );
        return req;
    } catch (error) {
        console.error("handlePutVote", error);
        throw new Error(error);
    }
};
