import React, { useState } from "react";
import useSimulator from "../../hooks/useSimulator";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import styled from "@emotion/styled";
import Error from "../../pages/Error";
import Footer from "../common/Footer";
import ShareIcon from "@mui/icons-material/Share";
import SharedResource from "../common/SharedResource";
import ErrorImage from "../../assets/404-error.svg";
import LoadingImage from "../../assets/loading-page.svg";

const Layout = (props) => {
    const { components: Component } = props;

    //STATES
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const { slug } = useParams();
    const id = localStorage.getItem("id");

    if (slug !== id) {
        localStorage.setItem("sendVote", false);
    }

    const simulator = useSimulator(slug);

    //FUNCTIONS
    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    
    return (
        <>
            {simulator.simulatorInfo === null ? (
                <Error image={LoadingImage} message="Buscando simulador..." />
            ) : !simulator.simulatorInfo ||
              simulator?.simulatorInfo?.length === 0 ? (
                <Error
                    image={ErrorImage}
                    message="No pudimos encontrar ningún simulador"
                />
            ) : (
                <>
                    <SharedResource
                        handleCloseModal={handleCloseModal}
                        modalIsOpen={modalIsOpen}
                    />
                    <NavBar simulator={simulator.simulatorInfo} />
                    <Template
                        URL={process.env.REACT_APP_CMS_ADEN}
                        bg={
                            simulator?.simulatorInfo[0]?.attributes?.bg_image
                                ?.data?.attributes?.url
                        }
                    >
                        <Component {...simulator} />
                        <ShareButtonWrapper
                            onClick={() => setModalIsOpen(true)}
                        >
                            <ShareIcon />
                        </ShareButtonWrapper>
                    </Template>
                    <Footer />
                </>
            )}
        </>
    );
};

export default Layout;

const Template = styled.div`
    width: 100%;
    min-height: 100vh;
    height: 100%;
    /* position: relative; */
    background-image: ${(p) => `
        url(${p.URL + p.bg})
    `};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 820px) {
        margin-top: 84px;
    }
`;

const ShareButtonWrapper = styled.div`
    position: fixed;
    bottom: 10px;
    right: 10px;
    transition: all 0.3s ease-in-out;
    padding: 0.5rem;
    height: calc(40px - 1rem);
    width: calc(40px - 1rem);
    border-radius: 50%;
    cursor: pointer;
    z-index: 9;
    background-color: #555;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
        background-color: #333;
    }
    svg {
        padding-right: 0.1rem;
        color: #fff;
        font-size: 1.25rem;
    }
`;
