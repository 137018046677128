import { createBrowserRouter } from "react-router-dom";
import Main from "../pages/Main";
import Layout from "../components/layout/Layout";
import Error from "../pages/Error";
import Postulation from "../pages/Postulation";
import Votes from "../pages/Votes";
import Results from "../pages/Results";
import ErrorImage from "../assets/404-error.svg";

export const router = createBrowserRouter([
    {
        path: `/:slug`,
        element: <Layout components={Main} />,
    },

    {
        path: `/`,
        element: (
            <Error
                image={ErrorImage}
                message="No pudimos encontrar ningún simulador"
            />
        ),
    },

    {
        path: `/postulacion/:slug`,
        element: <Layout components={Postulation} />,
    },

    {
        path: `/votacion/:slug`,
        element: <Layout components={Votes} />,
    },

    {
        path: `/resultados/:slug`,
        element: <Layout components={Results} />,
    },
]);
