import styled from "@emotion/styled";
import React from "react";
import NotResults from "../../assets/not-results.svg";

const NotFound = (props) => {
    const { message } = props;

    return (
        <Wrapper>
            <img src={NotResults} alt="" />
            <p>{message}</p>
        </Wrapper>
    );
};

export default NotFound;

const Wrapper = styled.div`
    width: calc(100% - 4rem);
    padding: 2rem;
    height: 30vh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    border-radius: 20px;

    img {
        height: 80%;
        width: auto;
    }

    p {
        font-size: 1.8rem;
        text-align: center;
    }
`;
