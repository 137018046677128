import axios from "axios";

export const getSimulator = async (slug) => {
    try {
        const req = await axios.get(
            `${process.env.REACT_APP_CMS_ADEN}/api/contest-simulators?filters[slug][$eq]=${slug}&populate=*`
        );
        return req.data.data;
    } catch (error) {
        console.error("getSimulator", error);
        throw new Error(error);
    }
};
