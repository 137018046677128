import styled from "@emotion/styled";
import React from "react";
import FormPostulation from "../components/postulation/FormPostulation";
import useSendPostulation from "../hooks/useSendPostulation";
import BannerCommon from "../components/votes/BannerCommon";

const Postulation = (props) => {
    const { simulatorInfo } = props;

    const {
        sendPostulation,
        sendPostulationJSON,
        handleCloseModal,
        loadingPostulation,
        errorPostulation,
        openModal,
    } = useSendPostulation();

    const enabledPostulation = simulatorInfo[0].attributes.enabled_postulation;
    const logoImg = `${process.env.REACT_APP_CMS_ADEN}${simulatorInfo[0].attributes.logo_color.data.attributes.url}`;
    const colorPrimary = simulatorInfo[0].attributes.primary_color;

    return (
        <Wrapper className="layout">
            {enabledPostulation ? (
                <>
                    <FormPostulation
                        sendPostulation={sendPostulation}
                        simulatorInfo={simulatorInfo}
                        sendPostulationJSON={sendPostulationJSON}
                        loadingPostulation={loadingPostulation}
                        errorPostulation={errorPostulation}
                        openModal={openModal}
                        handleCloseModal={handleCloseModal}
                    />
                </>
            ) : (
                <BannerCommon
                    enabled={enabledPostulation}
                    img={logoImg}
                    title="Postulación finalizada"
                    description="El formulario fue cerrado por el administrador."
                    colorPrimary={colorPrimary}
                    textButton="Ver resultados"
                    textVoteButton="Ir a votar"
                />
            )}
        </Wrapper>
    );
};

export default Postulation;

const Wrapper = styled.div`
    h1 {
        color: #fff;
    }
`;
