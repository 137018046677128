import styled from "@emotion/styled";
import { Box, Modal } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ModalConfirmPostulation = (props) => {
    const { openModal, handleCloseModal, colorPrimary, errorPostulation } =
        props;

    const navigate = useNavigate();
    const slug = localStorage.getItem("id");

    return (
        <ModalFake
            open={openModal}
            // onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <BoxFake>
                <Header
                    colorPrimary={
                        !errorPostulation ? `#${colorPrimary}` : "#FC2958"
                    }
                >
                    {errorPostulation ? (
                        <h3>¡Error inesperado!</h3>
                    ) : (
                        <h3>¡Postulación confirmada!</h3>
                    )}
                </Header>
                <Body>
                    {errorPostulation ? (
                        <p>
                            Ocurrió un error al enviar su postulación, por favor
                            intente nuevamente.
                        </p>
                    ) : (
                        <p>Muchas gracias por participar</p>
                    )}
                </Body>
                <WrapperButtons>
                    {!errorPostulation ? (
                        <>
                            <Button
                                colorPrimary={`#${colorPrimary}`}
                                colorText={"#fff"}
                                onClick={() => navigate(`../votacion/${slug}`)}
                            >
                                Ir a votar
                            </Button>
                            <Button
                                colorPrimary={"#fff"}
                                colorText={`#${colorPrimary}`}
                                onClick={() =>
                                    navigate(`../resultados/${slug}`)
                                }
                                border={true}
                            >
                                Ver resultados parciales
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                colorPrimary={`#${colorPrimary}`}
                                colorText={"#fff"}
                                onClick={handleCloseModal}
                            >
                                Volver a intentar
                            </Button>{" "}
                        </>
                    )}
                </WrapperButtons>
            </BoxFake>
        </ModalFake>
    );
};

export default ModalConfirmPostulation;

const ModalFake = styled(Modal)``;

const BoxFake = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(420px - 4rem);
    padding: 2rem;
    background-color: #fff;
    box-shadow: 24px;
    display: flex;
    flex-direction: column;
    justify-content: ${(p) => (p.isLoading ? "center" : "space-between")};
    align-items: Center;
    gap: 1.5rem;
    border-radius: 20px;
    overflow: hidden;

    small {
        color: #616161;
        font-size: 0.9rem;
    }

    @media (max-width: 620px) {
        width: calc(85% - 4rem);
    }
`;

const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    p {
        text-align: center;
        color: #616161;
        font-size: 0.9rem;
        letter-spacing: 0.0025em;
        line-height: 20px;

        span {
            font-weight: bold;
        }
    }
`;

const Header = styled.div`
    h3 {
        color: ${(p) => p.colorPrimary};
        text-align: center;
        font-size: 1.5rem;
    }
`;

const WrapperButtons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
`;

const Button = styled.button`
    width: 100%;
    color: ${(p) => p.colorText};
    background-color: ${(p) => p.colorPrimary};
    border: none;
    padding: 1rem;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    border: ${(p) => (p.border ? "solid 1px #e0e0e0" : "none")};
`;
