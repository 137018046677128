import { useEffect, useState } from "react";
import { getSimulator } from "../services/api/simulator";

const useSimulator = (slug) => {
    // borra local storage si no es el mismo slug
    const localStorageId = localStorage.getItem("id");

    if (!!localStorageId && localStorageId !== slug) {
        localStorage.clear();
    }

    // STATES
    const [simulatorInfo, setSimulatorInfo] = useState(null);
    const [loading, setLoading] = useState(
        simulatorInfo === null ? true : false
    );

    // EFFECTS
    useEffect(() => {
        !simulatorInfo && getSimulatorInfo();

        // eslint-disable-next-line
    }, [simulatorInfo]);

    // FUNCTIONS
    const refreshSimulatorInfo = () => {
        getSimulatorInfo();
    };

    const getSimulatorInfo = async () => {
        setLoading(true);
        try {
            const result = await getSimulator(slug);

            localStorage.setItem("id", slug);
            localStorage.setItem("simulatorData", JSON.stringify(result));

            setSimulatorInfo(result);
            setLoading(false);
        } catch (error) {
            setSimulatorInfo(false);
            setLoading(false);
        }
    };

    return { simulatorInfo, loading, refreshSimulatorInfo };
};

export default useSimulator;
