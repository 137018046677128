import styled from "@emotion/styled";
import React from "react";
import { useNavigate } from "react-router-dom";

const BannerCommon = (props) => {
    const {
        enabled,
        img,
        title,
        description,
        colorPrimary,
        textButton,
        textVoteButton,
    } = props;

    const slug = localStorage.getItem("id");
    const navigate = useNavigate();

    return (
        <Wrapper enabled={enabled} textButton={textButton}>
            <Image>
                <img src={img} alt="logo simulador" />
            </Image>
            <Info>
                <h2>{title}</h2>
                <p>{description}</p>
            </Info>
            <WrapperButtons>
                {!enabled && textVoteButton && (
                    <Button
                        colorText="#fff"
                        colorPrimary={`#${colorPrimary}`}
                        onClick={() => navigate(`../votacion/${slug}`)}
                    >
                        {textVoteButton}
                    </Button>
                )}
                {!enabled && textButton && (
                    <Button
                        colorText="#fff"
                        colorPrimary={`#${colorPrimary}`}
                        onClick={() => navigate(`../resultados/${slug}`)}
                    >
                        {textButton}
                    </Button>
                )}
            </WrapperButtons>
        </Wrapper>
    );
};

export default BannerCommon;

const Wrapper = styled.div`
    width: ${(p) =>
        p.textButton && !p.enabled ? "400px" : "calc(100% - 6rem)"};
    padding: 3rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 2rem;
    border-radius: 20px;
`;

const Image = styled.div`
    width: 190px;

    img {
        width: 100%;
        object-fit: contain;
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;

    h2 {
        color: #222222;
        font-size: 1.1rem;
    }

    p {
        color: #616161;
        font-size: 0.9rem;
    }
`;

const Button = styled.button`
    width: 100%;
    color: ${(p) => p.colorText};
    background-color: ${(p) => p.colorPrimary};
    border: none;
    padding: 1rem;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
`;

const WrapperButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
`;
