import styled from "@emotion/styled";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import {
    archiveSchema,
    linkArchiveSchema,
    linkVideoSchema,
} from "../../utils/schemas";
import DefaultField from "../common/DefaultField";
import Youtube from "../../assets/youtube-icon.png";
import Vimeo from "../../assets/vimeo-icon.png";
import DropBox from "../../assets/dropbox-icon.png";
import Drive from "../../assets/drive-icon.png";
import Loading from "../common/Loading";
import DefaultFile from "../common/DefaultFile";
import ModalConfirmPostulation from "./ModalConfirmPostulation";
import { useParams } from "react-router-dom";

const FormPostulation = (props) => {
    const {
        simulatorInfo,
        sendPostulation,
        sendPostulationJSON,
        loadingPostulation,
        errorPostulation,
        openModal,
        handleCloseModal,
    } = props;

    //STATES
    const [typeSelected, setTypeSelected] = useState("project_file");

    const { slug } = useParams();

    const simulator = simulatorInfo[0].attributes;
    const imageURL = `${process.env.REACT_APP_CMS_ADEN}${simulator.logo_color.data.attributes.url}`;
    const colorPrimary = simulator.primary_color;

    const initialValues = {
        captain_fullname: "",
        captain_email: "",
        project_name: "",
        participants_names: "",
        project_description: "",
        video_link: null,
        file_link: null,
        project_file: null,
    };

    // FUNCTIONS
    const handleTypeSelect = (type) => {
        setTypeSelected(type);
    };

    const handleSubmit = (values) => {
        const { project_file } = values;

        const body = {
            ...values,
            contest_simulator: slug === "generico" ? 3 : 1,
        };

        const bodyReq = {
            ...body,
        };

        if (typeSelected !== "project_file") {
            const data = {
                data: {
                    ...values,
                    contest_simulator: 1,
                },
            };
            sendPostulationJSON(data);
        } else {
            delete bodyReq.project_file;
            sendPostulation(bodyReq, project_file);
        }
    };

    return (
        <>
            <ModalConfirmPostulation
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                colorPrimary={colorPrimary}
                errorPostulation={errorPostulation}
            />

            <Wrapper>
                <Header>
                    <Image>
                        <img src={imageURL} alt="Logo empresa" />
                    </Image>
                    <Titles>
                        <h2>Formulario de postulación</h2>
                        <p>
                            Completa los datos del formulario para participar
                            del concurso.
                        </p>
                    </Titles>
                </Header>

                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={
                        typeSelected === "file_link"
                            ? linkArchiveSchema
                            : typeSelected === "project_file"
                            ? archiveSchema
                            : linkVideoSchema
                    }
                >
                    {({ setFieldValue }) => (
                        <Container>
                            <Row>
                                <DefaultField
                                    name="captain_fullname"
                                    type="text"
                                    label="Nombre completo"
                                />
                                <DefaultField
                                    name="captain_email"
                                    type="text"
                                    label="Email"
                                />
                            </Row>
                            <DefaultField
                                name="project_name"
                                type="text"
                                label="Nombre de la estación"
                            />
                            <DefaultField
                                name="participants_names"
                                type="text"
                                label="Integrantes"
                            />
                            <DefaultField
                                name="project_description"
                                type="text"
                                label="Descripción"
                            />

                            <ArchivesWrapper>
                                <ButtonsWrapper>
                                    <Button
                                        colorPrimary={`#${colorPrimary}`}
                                        onClick={() => {
                                            handleTypeSelect("project_file");
                                        }}
                                        typeSelected={typeSelected}
                                        idSelected="project_file"
                                    >
                                        Subir archivo
                                    </Button>
                                    <Button
                                        colorPrimary={`#${colorPrimary}`}
                                        onClick={() => {
                                            handleTypeSelect("video_link");
                                        }}
                                        typeSelected={typeSelected}
                                        idSelected="video_link"
                                    >
                                        Link de video
                                    </Button>
                                    <Button
                                        colorPrimary={`#${colorPrimary}`}
                                        onClick={() => {
                                            handleTypeSelect("file_link");
                                        }}
                                        typeSelected={typeSelected}
                                        idSelected="file_link"
                                    >
                                        Link de archivo
                                    </Button>
                                </ButtonsWrapper>

                                <Texts>
                                    {typeSelected === "project_file" ? (
                                        <>
                                            <p>Formatos permitidos: .mp4</p>
                                            <p>Tamaño máximo 25MB</p>
                                        </>
                                    ) : typeSelected === "video_link" ? (
                                        <>
                                            <WrapperIcons>
                                                <img
                                                    src={Youtube}
                                                    alt="youtube ícono"
                                                />
                                                <img
                                                    src={Vimeo}
                                                    alt="vimeo ícono"
                                                />
                                            </WrapperIcons>
                                            <p>
                                                ¡Recuerda que tu video debe
                                                estar público!
                                            </p>
                                        </>
                                    ) : typeSelected === "file_link" ? (
                                        <>
                                            <WrapperIcons>
                                                <img
                                                    src={DropBox}
                                                    alt="youtube ícono"
                                                />
                                                <img
                                                    src={Drive}
                                                    alt="vimeo ícono"
                                                />
                                            </WrapperIcons>
                                            <p>
                                                ¡Recuerda que tu archivo debe
                                                estar público!
                                            </p>
                                        </>
                                    ) : null}
                                </Texts>

                                <WrapperLinks>
                                    {typeSelected === "project_file" && (
                                        <DefaultFile
                                            handleB64File
                                            name="project_file"
                                            setFieldValue={setFieldValue}
                                        />
                                    )}
                                    {typeSelected === "video_link" && (
                                        <DefaultField
                                            name="video_link"
                                            type="text"
                                            label="Link de video"
                                        />
                                    )}
                                    {typeSelected === "file_link" && (
                                        <DefaultField
                                            name="file_link"
                                            type="text"
                                            label="Link de archivo"
                                        />
                                    )}
                                </WrapperLinks>
                            </ArchivesWrapper>
                            {!loadingPostulation ? (
                                <SendButton
                                    colorPrimary={`#${colorPrimary}`}
                                    type="submit"
                                >
                                    Enviar postulación
                                </SendButton>
                            ) : (
                                <Loading colorPrimary={colorPrimary} />
                            )}
                        </Container>
                    )}
                </Formik>
            </Wrapper>
        </>
    );
};

export default FormPostulation;

const Wrapper = styled.div`
    margin: 0 auto;
    width: calc(692px - 6rem);
    background-color: #fff;
    padding: 3rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    @media (max-width: 1000px) {
        width: calc(85% - 6rem);
    }
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
`;

const Image = styled.div`
    width: 190px;

    img {
        width: 100%;
        object-fit: contain;
    }
`;

const Titles = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;

    h2 {
        color: #222222;
        font-size: 1.1rem;
    }

    p {
        color: #616161;
        font-size: 0.9rem;
    }
`;

const Container = styled(Form)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
`;

const Row = styled.div`
    display: flex;
    width: 100%;
    gap: 1.5rem;
    align-items: center;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const ArchivesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    width: 100%;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    height: 47px;
    border-radius: 8px;
    border: solid 1.5px #c4c4c4;
    gap: 0.3rem;

    @media (max-width: 678px) {
        height: 37px;
    }
`;

const Button = styled.button`
    width: 100%;

    color: ${(p) =>
        p.typeSelected === p.idSelected ? p.colorPrimary : "#616161"};
    background-color: ${(p) =>
        p.typeSelected === p.idSelected
            ? `${p.colorPrimary}66`
            : "transparent"};
    border: none;
    padding: 1rem;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    border: ${(p) => (p.border ? "solid 1px #e0e0e0" : "none")};

    transition: all 0.3s ease-in-out;

    :hover {
        background-color: ${(p) =>
            p.typeSelected !== p.idSelected ? "#eee" : `${p.colorPrimary}66`};
    }

    @media (max-width: 678px) {
        padding: 0.8rem;

        font-size: 10px;
    }

    @media (max-width: 495px) {
        padding: 0.75rem;

        font-size: 8.5px;
    }
`;

const WrapperIcons = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
`;

const SendButton = styled.button`
    width: fit-content;
    color: #fff;
    background-color: ${(p) => p.colorPrimary};
    padding: 1rem;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    border: none;

    @media (max-width: 600px) {
        width: 100%;
    }
    @media (max-width: 500px) {
        font-size: 12px;
    }
`;

const WrapperLinks = styled.div`
    width: 100%;
`;

const Texts = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    p {
        font-size: 0.9rem;
        color: #616161;
        text-align: center;
    }
`;
