import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import ModalViewProject from "./ModalViewProject";
import ModalConfirmVote from "./ModalConfirmVote";
import ImgSimulatorMarte from "../../assets/porvenir_webinar_elovingyou.png";

const CardVotes = (props) => {
    const {
        infoPostulation,
        colorPrimary,
        handleSendVote,
        loadingVote,
        errorVote,
        resetErrorVote,
        upDateVote,
    } = props;

    const postulation = infoPostulation?.attributes;
    const id = infoPostulation.id;

    // url images
    const projectFile =
        postulation?.project_file?.data?.length > 0
            ? process.env.REACT_APP_CMS_ADEN +
              postulation?.project_file?.data[0]?.attributes?.url
            : null;

    const videoLink = postulation?.video_link;
    const file_link = postulation?.file_link;

    // STATES
    const [openModal, setOpenModal] = useState(false);
    const [openModalVote, setOpenModalVote] = useState(false);
    const [numberVotes, setNumberVotes] = useState(postulation?.votes);

    useEffect(() => {
        !!upDateVote.update &&
            id === upDateVote.id &&
            setNumberVotes(numberVotes + 1);

        // eslint-disable-next-line
    }, [upDateVote]);

    // FUNCTIONS
    // funciones de modal ver proyectos
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenModalVote = () => {
        resetErrorVote();
        setOpenModalVote(true);
    };

    const handleCloseModalVote = () => {
        setOpenModalVote(false);
    };

    return (
        <>
            <ModalViewProject
                openModal={openModal}
                handleCloseModal={handleCloseModal}
                postulation={postulation}
                colorPrimary={colorPrimary}
                handleSendVote={handleSendVote}
                id={id}
                loadingVote={loadingVote}
                errorVote={errorVote}
                resetErrorVote={resetErrorVote}
                upDateVote={upDateVote}
                projectFile={projectFile}
                videoLink={videoLink}
                file_link={file_link}
            />
            <ModalConfirmVote
                openModalVote={openModalVote}
                handleCloseModalVote={handleCloseModalVote}
                postulation={postulation}
                colorPrimary={colorPrimary}
                handleSendVote={handleSendVote}
                errorVote={errorVote}
                id={id}
                loadingVote={loadingVote}
            />
            <Wrapper>
                <WrapperButtons className="buttonsWrapper">
                    <Button
                        onClick={handleOpenModal}
                        colorPrimary={`#${colorPrimary}`}
                        colorText={"#fff"}
                    >
                        Ver proyecto
                    </Button>
                    <Button
                        onClick={handleOpenModalVote}
                        colorPrimary={"#fff"}
                        colorText={`#${colorPrimary}`}
                    >
                        Votar
                    </Button>
                </WrapperButtons>
                <Header>
                    <img src={ImgSimulatorMarte} alt="imagen proyecto" />
                </Header>
                <Body>
                    <TextWrapper>
                        <h3>{postulation?.project_name}</h3>
                        <span>{postulation?.participants_names}</span>
                    </TextWrapper>
                    <InfoVotes colorPrimary={`#${colorPrimary}`}>
                        <span>{numberVotes}</span>
                        <p>votos</p>
                    </InfoVotes>
                </Body>
            </Wrapper>
        </>
    );
};

export default CardVotes;

const Wrapper = styled.div`
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    z-index: 2;

    .buttonsWrapper {
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }
    ::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        background-color: #00000085;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    :hover::after {
        opacity: 1;
    }

    :hover {
        .buttonsWrapper {
            opacity: 1;
        }
    }
`;

const WrapperButtons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 50%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0%);
    z-index: 3;
`;

const Button = styled.button`
    width: 100%;
    color: ${(p) => p.colorText};
    background-color: ${(p) => p.colorPrimary};
    border: none;
    padding: 1rem;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
`;

const Header = styled.div`
    width: 100%;
    height: 151px;

    overflow: hidden;

    img {
        width: 100%;
        object-fit: contain;
    }
`;

const Body = styled.div`
    width: calc(100% - 2.4rem);
    padding: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    justify-content: flex-start;
    max-width: 70%;

    h3 {
        color: #000;
        font-size: 1rem;
    }

    span {
        color: #616161;
        font-size: 0.7rem;
    }
`;

const InfoVotes = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(48px - 2rem);
    height: calc(48px - 2rem);

    padding: 1rem;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.colorPrimary};
    border-radius: 8px;

    span {
        color: #fff;
        font-weight: bold;
        font-size: 1.2rem;
    }

    p {
        color: #fff;
        font-size: 0.78rem;
    }
`;
