import axios from "axios";
import { useState } from "react";

const useSendPostulation = () => {
    const isPostulation = JSON.parse(localStorage.getItem("sendPostulation"));

    // STATES
    const [loadingPostulation, setLoadingPostulation] = useState(false);
    const [errorPostulation, setErrorPostulation] = useState(false);
    const [openModal, setOpenModal] = useState(isPostulation ? true : false);

    // FUNCTIONS
    const sendPostulation = async (body, file) => {
        setLoadingPostulation(true);
        setErrorPostulation(false);

        var formdata = new FormData();

        formdata.append("data", JSON.stringify(body));
        formdata.append("files.project_file", file);

        console.log("formdata", formdata);

        try {
            await axios.post(
                `${process.env.REACT_APP_CMS_ADEN}/api/contest-postulations`,
                formdata
            );

            setLoadingPostulation(false);
            localStorage.setItem("sendPostulation", true);
            setOpenModal(true);
        } catch (error) {
            setErrorPostulation(true);
            setLoadingPostulation(false);
            console.log("error", error);
        }

        // if(req)
    };

    const sendPostulationJSON = async (body) => {
        setLoadingPostulation(true);
        setErrorPostulation(false);
        try {
            await axios.post(
                `${process.env.REACT_APP_CMS_ADEN}/api/contest-postulations`,
                body
            );

            setLoadingPostulation(false);
            localStorage.setItem("sendPostulation", true);
            setOpenModal(true);
        } catch (error) {
            setErrorPostulation(true);
            setOpenModal(true);
            setLoadingPostulation(false);
            console.log("error", error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return {
        sendPostulation,
        sendPostulationJSON,
        handleCloseModal,
        loadingPostulation,
        errorPostulation,
        openModal,
    };
};

export default useSendPostulation;
