import styled from "@emotion/styled";
import React from "react";

const Footer = () => {
    return (
        <Container>
            <p>
                Todos los derechos reservados | © ADEN{" "}
                {new Date().getFullYear()}
            </p>
        </Container>
    );
};

export default Footer;

const Container = styled.div`
    background-color: #252525;
    width: 100%;
    padding: 1rem 0;
    height: calc(84px - 2rem);
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        color: #fff;
        font-size: 1.1rem;
        text-align: center;
    }
`;
