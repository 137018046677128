import styled from "@emotion/styled";
import React from "react";
import BannerCommon from "../components/votes/BannerCommon";
import GridResults from "../components/results/GridResults";
import { useEffect } from "react";

const Results = (props) => {
    const { simulatorInfo, refreshSimulatorInfo } = props;

    const enabled = simulatorInfo[0]?.attributes?.enabled_vote;

    // EFFECTS
    useEffect(() => {
        refreshSimulatorInfo();

        // eslint-disable-next-line
    }, []);

    return (
        <Wrapper className="layout">
            <BannerCommon
                enabled={enabled}
                img={`${process.env.REACT_APP_CMS_ADEN}${simulatorInfo[0].attributes.logo_color.data.attributes.url}`}
                title={enabled ? "Resultados parciales" : "Resultados finales"}
                description={
                    enabled
                        ? "¡El proyecto con mayor cantidad de votos será el ganador!"
                        : "¡Felicitaciones a todos los participantes!"
                }
            />
            <GridResults
                colorPrimary={simulatorInfo[0].attributes.primary_color}
                postulations={
                    simulatorInfo[0]?.attributes?.contest_postulations?.data
                }
            />
        </Wrapper>
    );
};

export default Results;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
