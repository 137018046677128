import React from "react";
import styled from "@emotion/styled";
const Error = (props) => {
    const { image, message } = props;

    return (
        <Wrapper>
            <img src={image} alt="logo error" />
            <p>{message}</p>
        </Wrapper>
    );
};

export default Error;

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    img {
        height: 80%;
        width: auto;
    }

    p {
        font-size: 2rem;
        text-align: center;
    }

    /* ::before {
        content: "Kinder";
        font-size: 1.2rem;
        color: #b31d15;
        
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    } */

    @media (max-width: 768px) {
        img {
            width: 70%;
            height: auto;
        }

        p {
            font-size: 1.2rem;
            text-align: center;
        }
    }
`;
