import React from "react";

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";

import { IconButton, Modal } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import styled from "@emotion/styled";

const SharedResource = (props) => {
    const { modalIsOpen, handleCloseModal } = props;

    // STATE
    const link = window.location.href;

    if (!link) {
        return null;
    }

    return (
        <ModalFake open={modalIsOpen} onClose={handleCloseModal}>
            <SharedResourceContainer>
                {/** Close */}
                <CloseButtonWrapper>
                    <IconButton
                        onClick={handleCloseModal}
                        style={{
                            borderRadius: "10px",
                            margin: 0,
                            backgroundColor: "#f3f6f9",
                        }}
                        size="small"
                    >
                        <CloseRounded />
                    </IconButton>
                </CloseButtonWrapper>
                {/** Socials */}
                <SocialsWrapper>
                    <SocialsText>
                        ¿Te emociona este simulador?
                        <br />
                        <span>¡Compártelo!</span>
                    </SocialsText>
                    <SocialsGrid>
                        <FacebookShareButton onClick={() => ""} url={link}>
                            <FacebookIcon round size={48} />
                        </FacebookShareButton>
                        <TwitterShareButton onClick={() => ""} url={link}>
                            <TwitterIcon round size={48} />
                        </TwitterShareButton>
                        <LinkedinShareButton onClick={() => ""} url={link}>
                            <LinkedinIcon round size={48} />
                        </LinkedinShareButton>
                        <WhatsappShareButton onClick={() => ""} url={link}>
                            <WhatsappIcon round size={48} />
                        </WhatsappShareButton>
                    </SocialsGrid>
                </SocialsWrapper>
            </SharedResourceContainer>
        </ModalFake>
    );
};

export default SharedResource;

const ModalFake = styled(Modal)`
    div:nth-child(0n + 3) {
        border-radius: 20px;
    }
`;

const SharedResourceContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 4rem;
    width: max-content;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

const CloseButtonWrapper = styled.div`
    position: absolute;
    top: 5%;
    right: 5%;
`;

const SocialsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    text-align: center;
`;

const SocialsText = styled.div`
    font-size: 1.5rem;
    font-weight: 900;
    color: #222222;

    span {
        font-weight: 400;
        font-size: 1.2rem;
        color: #616161;
    }
`;

const SocialsGrid = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 1rem;
`;
