import React from "react";
import { ErrorMessage, Field } from "formik";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const DefaultField = (props) => {
    const { name, type, label } = props;

    // RETURN
    return (
        <FieldFake
            as={TextField}
            name={name}
            helperText={<ErrorMessage name={name} component={CustomError} />}
            type={type}
            label={label}
            variant="outlined"
            color="primary"
            fullWidth
            {...props}
        />
    );
};

export default DefaultField;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: #f8423f;
`;

const FieldFake = styled(Field)`
    fieldset {
        border-radius: 8px;
        border: solid 1.82px #c4c4c4;
    }
`;
