import React from "react";
import { StylesProvider } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function MaterialThemeProvider(props) {
    const theme = createTheme({
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 25,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                    },
                },
            },
        },
        typography: {
            fontSize: 14,
            button: {
                textTransform: "none",
                fontWeight: "600",
            },
        },
        palette: {
            primary: {
                main: "#333",
                // dark: will be calculated from palette.primary.main,
                contrastText: "#fff", //will be calculated to contrast with palette.primary.main
            },
            background: {
                default: "#f9f9f9 !important",
            },
            secondary: {
                // light: will be calculated from palette.primary.main,
                main: "#FFFFFF",
                contrastText: "#333",
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
            muted: {
                main: "#c4c4c4",
            },
            error: {
                // light: will be calculated from palette.primary.main,
                main: "#f8423f",
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
        },
        props: {
            // Name of the component ⚛️
            MuiButtonBase: {
                // The properties to apply
                disableRipple: false, // No more ripple, on the whole application 💣!
            },

            // Set default elevation to 1 for popovers.
            MuiPopover: {
                elevation: 1,
            },
        },
    });
    const { children } = props;

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StylesProvider>
    );
}
