import styled from "@emotion/styled";
import React from "react";
import BannerCommon from "../components/votes/BannerCommon";
import GridVotes from "../components/votes/GridVotes";

const Votes = (props) => {
    const { simulatorInfo } = props;

    const enabled = simulatorInfo[0]?.attributes?.enabled_vote;

    return (
        <Wrapper className="layout">
            <BannerCommon
                colorPrimary={simulatorInfo[0].attributes.primary_color}
                enabled={enabled}
                img={`${process.env.REACT_APP_CMS_ADEN}${simulatorInfo[0].attributes.logo_color.data.attributes.url}`}
                textButton="Ver resultados"
                title={
                    enabled
                        ? "¡Elige tu favorito y dale tu voto!"
                        : "Votación Finalizada"
                }
                description={
                    enabled
                        ? "Mira cada uno de los proyectos postulados y dale tu voto al que más te guste. Sólo puedes votar una vez."
                        : "La votación fue cerrada por el administrador."
                }
            />
            {enabled && (
                <GridVotes
                    id={simulatorInfo[0].id}
                    colorPrimary={simulatorInfo[0].attributes.primary_color}
                />
            )}
        </Wrapper>
    );
};

export default Votes;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
