import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

const NavBar = (props) => {
    const { simulator } = props;
    const data = simulator[0].attributes;
    const URL = `${process.env.REACT_APP_CMS_ADEN}${data.logo_white.data[0].attributes.url}`;

    // STATE
    const [pathName, setPathName] = useState();
    const [viewMenu, setViewMenu] = useState(false);

    // HOOKS
    const location = useLocation();
    const navigate = useNavigate();
    const slug = localStorage.getItem("id");

    // EFFECTS
    useEffect(() => {
        formatPathName();

        // eslint-disable-next-line
    }, []);

    // FUNCTIONS
    const handleNavigation = (tab) => {
        setPathName(tab);
        navigate(`/${tab}/${slug}`);
    };

    const formatPathName = () => {
        const pathname = location.pathname;

        setPathName(pathname.split("/")[1]);
    };

    const handleViewMenu = () => {
        setViewMenu(!viewMenu);
    };

    return (
        <Wrapper>
            <img src={URL} alt="Logo Simulador" />
            <WrapperButtons viewMenu={viewMenu}>
                <Button
                    selected={pathName === "postulacion" ? true : false}
                    onClick={() => {
                        handleViewMenu();
                        handleNavigation("postulacion");
                    }}
                    colorPrimary={data.primary_color}
                >
                    Postulación
                </Button>
                <Button
                    selected={pathName === "votacion" ? true : false}
                    onClick={() => {
                        handleViewMenu();
                        handleNavigation("votacion");
                    }}
                    colorPrimary={data.primary_color}
                >
                    Votación
                </Button>
                <Button
                    selected={pathName === "resultados" ? true : false}
                    onClick={() => {
                        handleViewMenu();
                        handleNavigation("resultados");
                    }}
                    colorPrimary={data.primary_color}
                >
                    Resultados
                </Button>
            </WrapperButtons>

            <WrapperIcon onClick={handleViewMenu} viewMenu={viewMenu}>
                <MenuIcon />
            </WrapperIcon>
        </Wrapper>
    );
};

export default NavBar;

const Wrapper = styled.div`
    width: cacl(100vw - 20rem);
    padding: 0 10rem;
    background-color: #252525;
    display: flex;
    height: 84px;
    justify-content: space-between;
    position: relative;
    align-items: center;
    z-index: 9;
    img {
        width: 130px;
        object-fit: contain;
    }

    @media (max-width: 820px) {
        position: fixed;
        top: 0;
        left: 0;
        width: calc(100% - 4rem);
        padding: 0 2rem;
    }
`;

const WrapperButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
    position: relative;

    transition: all 0.3s ease-in-out;

    @media (max-width: 820px) {
        width: calc(50% - 4rem);
        top: 84px;
        left: ${(p) => (p.viewMenu ? "0" : "-100%")};
        z-index: 2;
        padding: 4rem 2rem;
        border-bottom-right-radius: 20px;
        position: absolute;
        background-color: #252525;
        flex-direction: column;
        justify-content: space-between;
    }
`;

const Button = styled.button`
    color: white;

    background-color: ${(p) =>
        p.selected ? `#${p.colorPrimary}` : "transparent"};
    border: none;
    padding: 1rem;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    :hover {
        background-color: #f1f1f125;
    }

    @media (max-width: 820px) {
        width: 100%;
    }
`;

const WrapperIcon = styled.div`
    width: calc(40px - 1rem);
    height: calc(40px - 1rem);
    display: none;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    background-color: ${(p) => (p.viewMenu ? "#fff" : "transparent")};
    cursor: pointer;

    transition: all 0.3s ease-in-out;

    svg {
        font-size: 1.6rem;
        color: ${(p) => (p.viewMenu ? "#000" : "#fff")};
    }

    @media (max-width: 820px) {
        display: flex;
    }
`;
