import styled from "@emotion/styled";
import { Box, Modal } from "@mui/material";
import React from "react";
import Loading from "../common/Loading";
import { useNavigate } from "react-router-dom";

const ModalConfirmVote = (props) => {
    const {
        openModalVote,
        handleCloseModalVote,
        postulation,
        colorPrimary,
        handleSendVote,
        id,
        loadingVote,
        errorVote,
    } = props;

    const isVote = JSON.parse(localStorage.getItem("sendVote"));

    const navigate = useNavigate();
    const slug = localStorage.getItem("id");

    return (
        <ModalFake
            open={openModalVote}
            onClose={handleCloseModalVote}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {loadingVote ? (
                <BoxFake isLoading={true}>
                    <Loading colorPrimary={colorPrimary} />
                    <small>Enviando tu voto...</small>
                </BoxFake>
            ) : (
                <BoxFake>
                    <Header
                        colorPrimary={
                            !errorVote ? `#${colorPrimary}` : "#FC2958"
                        }
                    >
                        {errorVote ? (
                            <h3>¡Error inesperado!</h3>
                        ) : (
                            <>
                                {!isVote ? (
                                    <h3>¿Confirmas tu voto?</h3>
                                ) : (
                                    <h3>¡Voto confirmado!</h3>
                                )}
                            </>
                        )}
                    </Header>
                    <Body>
                        {errorVote ? (
                            <p>
                                Ocurrió un error al enviar su voto, por favor
                                intente nuevamente.
                            </p>
                        ) : (
                            <>
                                {!isVote ? (
                                    <>
                                        <p>
                                            Vas a darle tu voto al proyecto{" "}
                                            <span>
                                                “{postulation?.project_name}”
                                            </span>
                                        </p>
                                        <p>
                                            Una vez confirmado no podrás votar a
                                            otro participante.
                                        </p>
                                    </>
                                ) : (
                                    <p>Muchas gracias por participar.</p>
                                )}
                            </>
                        )}
                    </Body>
                    <WrapperButtons>
                        {!isVote ? (
                            <>
                                <Button
                                    colorPrimary={`#${colorPrimary}`}
                                    colorText={"#fff"}
                                    onClick={() => handleSendVote(id)}
                                >
                                    Confirmar votación
                                </Button>
                                <Button
                                    colorPrimary={"#fff"}
                                    colorText={`#${colorPrimary}`}
                                    onClick={handleCloseModalVote}
                                    border={true}
                                >
                                    Cancelar
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    colorPrimary={`#${colorPrimary}`}
                                    colorText={"#fff"}
                                    onClick={() =>
                                        navigate(`../resultados/${slug}`)
                                    }
                                >
                                    Ver resultados parciales
                                </Button>
                                <Button
                                    colorPrimary={"#fff"}
                                    colorText={`#${colorPrimary}`}
                                    onClick={handleCloseModalVote}
                                    border={true}
                                >
                                    Cerrar
                                </Button>
                            </>
                        )}
                    </WrapperButtons>
                </BoxFake>
            )}
        </ModalFake>
    );
};

export default ModalConfirmVote;

const ModalFake = styled(Modal)``;

const BoxFake = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(420px - 4rem);
    padding: 2rem;
    background-color: #fff;
    box-shadow: 24px;
    display: flex;
    flex-direction: column;
    justify-content: ${(p) => (p.isLoading ? "center" : "space-between")};
    align-items: Center;
    gap: 1.5rem;
    border-radius: 20px;
    overflow: hidden;

    small {
        color: #616161;
        font-size: 0.9rem;
    }

    @media (max-width: 620px) {
        width: calc(85% - 4rem);
    }
`;

const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    p {
        text-align: center;
        color: #616161;
        font-size: 0.9rem;
        letter-spacing: 0.0025em;
        line-height: 20px;

        span {
            font-weight: bold;
        }
    }
`;

const Header = styled.div`
    h3 {
        color: ${(p) => p.colorPrimary};
        text-align: center;
        font-size: 1.5rem;
    }
`;

const WrapperButtons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
`;

const Button = styled.button`
    width: 100%;
    color: ${(p) => p.colorText};
    background-color: ${(p) => p.colorPrimary};
    border: none;
    padding: 1rem;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    border: ${(p) => (p.border ? "solid 1px #e0e0e0" : "none")};
`;
