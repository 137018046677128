import React, { useEffect } from "react";
import Loading from "../components/common/Loading";
import { useNavigate } from "react-router-dom";

const Main = () => {
    const navigate = useNavigate();
    const slug = localStorage.getItem("id");

    useEffect(() => {
        navigate(`/votacion/${slug}`);

        // eslint-disable-next-line
    }, []);

    return <Loading />;
};

export default Main;
