import { Suspense } from "react";
import Loading from "./components/common/Loading";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/routes";

function App() {
    return (
        <Suspense fallback={<Loading />}>
            <RouterProvider router={router} />
        </Suspense>
    );
}

export default App;
