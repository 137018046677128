import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

const CardResults = (props) => {
    const { postulation, colorPrimary, position } = props;

    const [colorRGB, setColorRGB] = useState(null);

    useEffect(() => {
        hexToRGB(colorPrimary);
    }, [colorPrimary]);

    const hexToRGB = (hex) => {
        // Eliminar el símbolo '#' si está presente
        hex = hex.replace("#", "");

        // Verificar si el valor hexadecimal es válido
        if (!/^[0-9A-Fa-f]{6}$/.test(hex)) {
            throw new Error("Valor hexadecimal inválido");
        }

        // Extraer los componentes de color (r, g, b)
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        setColorRGB({
            r,
            g,
            b,
        });
    };

    return (
        <Wrapper>
            <Position
                position={position}
                r={colorRGB?.r}
                g={colorRGB?.g}
                b={colorRGB?.b}
            >
                {position}
            </Position>
            <InfoProject>
                <h2>{postulation?.attributes?.project_name}</h2>
                <p>{postulation?.attributes?.participants_names}</p>
            </InfoProject>
            <VoteInfo>
                <p>
                    <span>{postulation?.attributes?.votes}</span> votos
                </p>
            </VoteInfo>
        </Wrapper>
    );
};

export default CardResults;

const Wrapper = styled.div`
    width: calc(100% - 3rem);
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
`;

const Position = styled.div`
    min-height: 48px;
    height: 48px;
    min-width: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 13.2641px;
    font-weight: bold;
    font-size: 1.1rem;

    ${(p) =>
        p.position === 1
            ? `background-color: 
                  rgb(${p.r - 47}, ${p.g - 44}, ${p.b - 34})`
            : p.position === 2
            ? `background-color:  rgb(${p.r}, ${p.g}, ${p.b})`
            : p.position === 3
            ? `background-color: 
                  rgb(${p.r + 10}, ${p.g + 36}, ${p.b + 6})`
            : `background-color: 
                  rgb(${p.r + 22}, ${p.g + 67}, ${p.b + 54})`};
`;

const InfoProject = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: center;

    h2 {
        color: #000;
        letter-spacing: 0.0025em;
        line-height: 22px;
        font-size: 1.1rem;
    }

    p {
        color: #616161;
        line-height: 17px;
        letter-spacing: 0.0025em;
        font-size: 0.9rem;
    }
`;

const VoteInfo = styled.div`
    width: auto;
    flex-basis: 20%;
    text-align: end;
    p {
        color: #000;
        font-size: 1.1rem;

        span {
            font-weight: bold;
        }
    }
`;
