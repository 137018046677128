import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";

export default function Loading(props) {
    const { colorPrimary } = props;

    return (
        <BoxFake sx={{ display: "flex" }}>
            <CircularProgressFake colorPrimary={`#${colorPrimary}`} />
        </BoxFake>
    );
}

const BoxFake = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CircularProgressFake = styled(CircularProgress)`
    svg {
        color: ${(p) => p.colorPrimary} !important;
    }
`;
