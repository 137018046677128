import { useEffect, useState } from "react";
import { getPostulations, handlePutVote } from "../services/api/postulations";

const usePostulations = (id) => {
    // STATES
    const [infoPostulations, setInfoPostulations] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingVote, setLoadingVote] = useState(false);
    const [errorVote, setErrorVote] = useState(false);
    const [upDateVote, setUpdateVote] = useState({
        update: false,
        id: null,
    });

    // EFFECTS
    useEffect(() => {
        infoPostulations === null && !!id && getInfoPostulations();

        // eslint-disable-next-line
    }, [infoPostulations]);

    // FUNCTIONS
    const getInfoPostulations = async () => {
        setLoading(true);

        try {
            const res = await getPostulations(id);
            setInfoPostulations(res);
            setLoading(false);
        } catch (error) {
            setInfoPostulations(false);
            setLoading(false);
        }
    };

    const resetErrorVote = () => {
        setErrorVote(false);
    };

    const handleSendVote = async (idPostulation) => {
        setLoadingVote(true);
        resetErrorVote();

        try {
            await handlePutVote(idPostulation);

            localStorage.setItem("sendVote", JSON.stringify(true));
            setUpdateVote({
                update: true,
                id: idPostulation,
            });
            setLoadingVote(false);
            setErrorVote(false);
        } catch (error) {
            localStorage.setItem("sendVote", JSON.stringify(false));
            setUpdateVote({
                update: false,
                id: null,
            });
            setLoadingVote(false);
            setErrorVote(true);
        }
    };

    return {
        infoPostulations,
        loading,
        loadingVote,
        errorVote,
        upDateVote,
        handleSendVote,
        resetErrorVote,
    };
};

export default usePostulations;
