import React, { useState } from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import styled from "@emotion/styled";
import { ErrorMessage } from "formik";

const DefaultFile = (props) => {
    const { name, setFieldValue } = props;

    const [fileState, setFileState] = useState(null);

    const handleChange = async (e) => {
        const file = e.target.files[0];
        if (file?.size >= 26214400) {
            alert("Tamaño máxido 25mb");
            return;
        }
        const size = formatSizeUnits(file?.size);

        if (
            file?.type
            // file?.type === "video/mp4" ||
            // file?.type === ".hevc" ||
            // file?.type === "video/hevc" ||
            // file?.type === "video/quicktime"
        ) {
            setFileState({
                name: file.name,
                size: size,
            });
        } else {
            alert("Formato no válido");
            setFileState(null);
            return;
        }
        // const fileTransform = await toBase64(file);
        setFieldValue(name, file);
    };

    const formatSizeUnits = (bytes) => {
        if (bytes >= 1073741824) {
            bytes = (bytes / 1073741824).toFixed(2) + " GB";
        } else if (bytes >= 1048576) {
            bytes = (bytes / 1048576).toFixed(2) + " MB";
        } else if (bytes >= 1024) {
            bytes = (bytes / 1024).toFixed(2) + " KB";
        } else if (bytes > 1) {
            bytes = bytes + " bytes";
        } else if (bytes === 1) {
            bytes = bytes + " byte";
        } else {
            bytes = "0 bytes";
        }
        return bytes;
    };

    return (
        <DefaultFileWrapper>
            <LabelInput for={name}>
                {/* Si el input esta vacio */}
                {!fileState && (
                    <>
                        <ButtonBlueFake>Seleccionar archivo</ButtonBlueFake>
                        <p>No hay archivos seleccionados</p>
                    </>
                )}
                {/* Si contiene algo */}
                {!!fileState && (
                    <>
                        <ButtonBlueFake>
                            <InsertDriveFileIcon /> {fileState.name}
                        </ButtonBlueFake>
                        <p fontSize=".8rem" color="#444444">
                            {fileState.size}
                        </p>
                    </>
                )}
            </LabelInput>
            <File onChange={handleChange} id={name} type="file" />
            <ErrorMessage name={name} component={CustomError} />
        </DefaultFileWrapper>
    );
};

export default DefaultFile;

const DefaultFileWrapper = styled.div`
    width: 100%;
`;

const File = styled.input`
    display: none;
`;

const LabelInput = styled.label`
    display: flex;
    width: calc(100% - 0.6rem);
    padding: 0.5rem;
    align-items: center;
    gap: 1rem;
    border-radius: 8px;
    border: solid 1.82px #c4c4c4;
    cursor: pointer;
    transition: 0.5s ease-in-out all;

    p {
        color: #616161;
    }

    @media (max-width: 600px) {
        p {
            font-size: 0.85rem;
            text-align: center;
        }
    }
`;

const ButtonBlueFake = styled.div`
    height: 38px;
    width: fit-content;
    padding: 0 0.5rem;
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    text-align: center;

    @media (max-width: 600px) {
        font-size: 0.85rem;
    }
`;

const CustomError = styled.span`
    font-size: 0.75rem;
    font-weight: 700;
    color: #f8423f;
`;
