import styled from "@emotion/styled";
import React from "react";
import usePostulations from "../../hooks/usePostulations";
import Loading from "../common/Loading";
import CardVotes from "./CardVotes";
import NotFound from "../common/NotFound";

const GridVotes = (props) => {
    const { id, colorPrimary } = props;

    const {
        infoPostulations,
        loading,
        loadingVote,
        errorVote,
        upDateVote,
        handleSendVote,
        resetErrorVote,
    } = usePostulations(id);

    return (
        <>
            {loading ? (
                <Container>
                    <Loading colorPrimary={colorPrimary} />
                </Container>
            ) : !infoPostulations || infoPostulations?.length === 0 ? (
                <NotFound message="No se encontraron postulaciones" />
            ) : (
                <Wrapper>
                    {infoPostulations.map((postulation) => (
                        <CardVotes
                            key={postulation.id}
                            infoPostulation={postulation}
                            colorPrimary={colorPrimary}
                            handleSendVote={handleSendVote}
                            loadingVote={loadingVote}
                            errorVote={errorVote}
                            resetErrorVote={resetErrorVote}
                            upDateVote={upDateVote}
                        />
                    ))}
                </Wrapper>
            )}
        </>
    );
};

export default GridVotes;

const Container = styled.div`
    width: 100%;
`;

const Wrapper = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
    gap: 1.5rem;
`;
