import styled from "@emotion/styled";
import React from "react";
import CardResults from "./CardResults";

const GridResults = (props) => {
    const { colorPrimary, postulations } = props;

    return (
        <Wrapper>
            {postulations
                .sort((a, b) => b.attributes.votes - a.attributes.votes)
                .map((postulation, index) => (
                    <CardResults
                        position={index + 1}
                        postulation={postulation}
                        colorPrimary={colorPrimary}
                        key={postulation?.id}
                    />
                ))}
        </Wrapper>
    );
};

export default GridResults;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 1.5rem;
`;
