import * as yup from "yup";

export const archiveSchema = yup.object().shape({
    captain_fullname: yup.string().nullable().required("Campo requerido"),
    captain_email: yup
        .string()
        .nullable()
        .email("Formato incorrecto")
        .required("Correo requerido")
        .max(320, "Máximo 320 caracteres"),
    project_name: yup
        .string()
        .nullable()
        .min(3, "Mínimo 3 caracteres")
        .max(140, "Máximo 140 caracteres")
        .required("Campo requerido"),
    participants_names: yup.string().nullable().required("Campo requerido"),
    project_description: yup.string().nullable().required("Campo requerido"),
    project_file: yup
        .mixed()
        // .test(
        //     "formato",
        //     "Formato de archivo no válido",
        //     (value) =>
        //         value &&
        //         ["video/hevc", ".hevc", "video/mp4", "video/quicktime"].includes(value.type)
        // )
        .test(
            "tamaño",
            "El archivo excede el tamaño máximo permitido de 25 MB",
            (value) => value && value.size <= 25 * 1024 * 1024
        )
        .required("Este campo es obligatorio"),
});

export const linkVideoSchema = yup.object().shape({
    captain_fullname: yup.string().nullable().required("Campo requerido"),
    captain_email: yup
        .string()
        .nullable()
        .email("Formato incorrecto")
        .required("Correo requerido")
        .max(320, "Máximo 320 caracteres"),
    project_name: yup
        .string()
        .nullable()
        .min(3, "Mínimo 3 caracteres")
        .max(140, "Máximo 140 caracteres")
        .required("Campo requerido"),
    participants_names: yup.string().nullable().required("Campo requerido"),
    project_description: yup.string().nullable().required("Campo requerido"),
    video_link: yup
        .string()
        .url("Ingresa una URL válida")
        .nullable()
        .required("Campo requerido"),
});

export const linkArchiveSchema = yup.object().shape({
    captain_fullname: yup.string().nullable().required("Campo requerido"),
    captain_email: yup
        .string()
        .nullable()
        .email("Formato incorrecto")
        .required("Correo requerido")
        .max(320, "Máximo 320 caracteres"),
    project_name: yup
        .string()
        .nullable()
        .min(3, "Mínimo 3 caracteres")
        .max(140, "Máximo 140 caracteres")
        .required("Campo requerido"),
    participants_names: yup.string().nullable().required("Campo requerido"),
    project_description: yup.string().nullable().required("Campo requerido"),
    file_link: yup
        .string()
        .url("Ingresa una URL válida")
        .nullable()
        .required("Campo requerido"),
});
